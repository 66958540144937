import React from "react"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import Layout from "../components/Layout/Layout"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"
import CookiePolicy from "../components/CookiePolicy/CookiePolicy"
import { graphql, useStaticQuery } from "gatsby"

const PageNotFound = () => {
  const data = useStaticQuery(graphql`
    query privacyCookiePageQuery {
      prismicBlindcvCookiePolicyPage {
        data {
          analytics {
            document {
              ... on PrismicBlindcvPageAnalytics {
                data {
                  event
                  page_title
                  page_type
                }
              }
            }
          }
          title
          content {
            html
            raw
          }
          table {
            html
            raw
            richText
            text
          }
        }
      }
    }
  `)

  const termsPage = data.prismicBlindcvCookiePolicyPage.data
  return (
    <div>
      <Layout pageViewAnalyticsData={
          data.prismicBlindcvCookiePolicyPage.data.analytics.document.data
        }>
        <NavigationBar />
        <div className="page-content">
          <CookiePolicy data={termsPage} />
          <SiteFooter />
        </div>
      </Layout>
    </div>
  )
}
export default PageNotFound
